import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

class FourOhFour extends React.Component {
    render() {
      return (
        <Layout location={this?.props?.location}>
            <div style={{textAlign: 'center'}}>
              <h1>Page not found</h1>
              <Link to='/'>
                Return home
              </Link>
            </div>
        </Layout>
      )
    }
  }
  
  export default FourOhFour